/* eslint-disable no-fallthrough */
import Icon from '@components/icon'
import TenantFilter from '@components/tenant-filter'
import {useAlertsSettings, useTenant} from '@store/settings'
import {Button, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions} from 'helpers/style'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

const getColumns = ({tenant, setTenant}) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant({id: record.tid, name: record.t_name})
          }}
        >
          {record.t_name}
        </Button>
      ),
      hidden: !!tenant
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type'
    },
    {
      title: 'Item Type',
      dataIndex: 'item_type',
      key: 'item_type'
    },
    {
      title: 'Item ID',
      dataIndex: 'item_id',
      key: 'item_id'
    },
    {
      title: (
        <>
          Site
          <Icon name="contains" />
          Gateway
          <Icon name="contains" />
          Camera
          <Icon name="contains" />
          Target
        </>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => {
        switch (row.item_type) {
          case 'site':
            return row.s_name
          case 'gateway':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
              </>
            )
          case 'camera':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
                <Icon name="contains" />
                {row.ca_name}
              </>
            )
          case 'target':
            return (
              <>
                {row.s_name}
                <Icon name="contains" />
                {row.g_name}
                <Icon name="contains" />
                {row.ca_name}
                <Icon name="contains" />
                {row.tg_name}
              </>
            )
          default:
            return 'Unknown type'
        }
      }
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text, row) => {
        return moment(row.timestamp).local().format('yyyy/MM/DD h:mm:ss A')
      }
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      render: (text, row) => {
        let name = 'comm-failure'
        if (text.toUpperCase().includes('WARNING')) {
          name = 'temp-warning'
        } else if (text.toUpperCase().includes('CRITICAL')) {
          name = 'temp-critical'
        }
        return (
          <>
            <Icon name={name} />
            {' ' + text}
          </>
        )
      }
    }
  ].filter(item => !item.hidden)

const Alerts = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const fetchAlertsview = useFetchGet('alertsview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useAlertsSettings()
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    const getAlerts = () => {
      const params = Params({filter, limit, page, orderBy: 'timestamp', order: 'DESC'})
      if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
      fetchAlertsview(params, response => {
        setData(response.alertsview)
        setMeta(response.meta)
      })
    }

    getAlerts()
  }, [filter, page, limit, tenant, user])

  const columns = useMemo(() => {
    return getColumns({tenant, setTenant})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Alerts"
        subTitle=""
        backIcon={false}
        extra={[
          <TenantFilter
            onFilter={value => {
              setFilter(value)
              setPage(1)
            }}
            filter={filter}
            tenant={tenant}
            onClearTenant={() => setTenant(null)}
          />
        ]}
      />

      <Table
        columns={columns}
        dataSource={data}
        rowKey={alert => alert.tid + '_' + alert.alert_type + '_' + alert.timestamp}
        loading={!data}
        pagination={{
          position: ['topRight', 'bottomRight'],
          total: meta?.total,
          current: Number(meta?.page) || 1,
          pageSize: limit,
          pageSizeOptions: pageSizeOptions,
          onChange: (_page, _limit) => {
            if (_page !== page) setPage(_page)
            if (_limit !== limit) setLimit(_limit)
            document.querySelector('main').scrollIntoView()
          }
        }}
      />
    </>
  )
}

export default Alerts
